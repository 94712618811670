<template>
  <div class="dealer-combination">
    <div class="dealer-combination-title">
      <span class="back" @click="handleGoBack"><i class="el-icon-back"></i> 返回</span>
      <span class="dealer-combination-title-name">{{title}}</span>
    </div>

    <el-row class="dealer-combination-content">
      <el-col :span="12" class="dealer-combination-param">
        <el-form label-width="100px" size="small">
          <el-form-item label="套餐编号" v-if="combination.uuid.length !==0">
            <p>{{combination.uuid}}</p>
          </el-form-item>
          <el-form-item label="套餐名称">
            <el-input v-model="combination.name" placeholder="请输入套餐名称"></el-input>
          </el-form-item>

          <el-form-item label="套餐时长(月)">
            <el-input v-model="combination.months" type="number" placeholder="请输入套餐时长"></el-input>
          </el-form-item>
          <el-form-item label="月租金">
            <el-input v-model="combination.rent"
                      @change="combination.rent=materielExtraCostChange(combination.rent)"
                      type="number" placeholder="请输入套餐月租金"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="10" class="dealer-combination-edit-info">
        <p>使用说明</p>
        <ol class="dealer-combination-edit-info-content">
          <li>普通用户进行扫码制作时，采用零售价进行计费</li>
          <li>若无免费杯数时,员工制作饮品使用计费饮品收费</li>
          <li>若有免费杯数时,员工制作饮品不计费</li>
          <li>套餐过期后设备自动转成零售模式进行计费</li>
          <li>若计费饮品价格为0，自动转化成免费饮品</li>
          <li class="alarm">套餐进行更新，仅对后期开通套餐的客户产生影响，更新之前的客户不受影响</li>
        </ol>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="dealer-combination-operate">
      <el-col :span="10" class="dealer-combination-cup">
        <p class="dealer-combination-cup-title">
          <span class="label">免费饮品</span>
          <span class="operate" @click="handleAddDrink(freeCups, 'free')"><i
            class="iconfont icontianjia"></i>添加饮品</span>
          <span class="operate" @click="handleCopyDrink(billingCups, freeCups)">
            <i class="el-icon-document-copy"></i>复制计费饮品
          </span>
        </p>
        <el-table :data="freeCups" size="mini">
          <el-table-column label="饮品名称">
            <template slot-scope="scope">
              {{scope.row.name}}/{{scope.row.engName}}
            </template>
          </el-table-column>
          <el-table-column label="免费杯数" width="90px">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cups" size="mini"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="80px">
            <template slot-scope="scope">
                <span class="delete" @click="handleDeleteFree(scope.row, scope.$index)">
                  <i class="iconfont iconshanchu "></i></span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>

      <el-col :span="14" class="dealer-combination-cup">
        <p class="dealer-combination-cup-title">
          <span class="label">计费饮品</span>
          <span class="operate" @click="handleAddDrink(billingCups, 'billing')"><i class="iconfont icontianjia"></i>添加饮品</span>
          <span class="operate" @click="handleCopyDrink(freeCups, billingCups)">
            <i class="el-icon-document-copy"></i>复制免费饮品
          </span>
        </p>
        <el-table :data="billingCups" size="mini">
          <el-table-column label="饮品名称">
            <template slot-scope="scope">
              {{scope.row.name}}/{{scope.row.engName}}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="小杯价格(元)" align="center" width="90">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price" size="mini" :min="0"
                        @change="scope.row.price=materielExtraCostChange(scope.row.price)"/>
            </template>
          </el-table-column>

          <el-table-column prop="price" label="中杯价格(元)" align="center" width="90">
            <template slot-scope="scope">
              <el-input v-model="scope.row.middlePrice" size="mini" :min="0"
                        @change="scope.row.middlePrice=materielExtraCostChange(scope.row.middlePrice)"/>
            </template>
          </el-table-column>

          <el-table-column prop="price" label="大杯价格(元)" align="center" width="90">
            <template slot-scope="scope">
              <el-input v-model="scope.row.bigPrice" size="mini" :min="0"
                        @change="scope.row.bigPrice=materielExtraCostChange(scope.row.bigPrice)"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="80px">
            <template slot-scope="scope">
                <span class="delete" @click="handleDeleteBilling(scope.row, scope.$index)"><i
                  class="iconfont iconshanchu "></i></span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>

    </el-row>

    <div class="dealer-combination-submit">
      <el-button type="primary" size="small" @click="handleCombinationCreate">提交</el-button>
    </div>

    <c-dialog :visible.sync="getDrinkVisible" :title="'设备饮品'" :width="'42rem'" :height="'36rem'">
      <el-button slot="title" size="mini" type="primary" @click="handleGetDrink">确定</el-button>
      <drink-list ref="drinkList"></drink-list>
    </c-dialog>


  </div>
</template>

<script>
  import CDialog from '@/components/Dialog'
  import DrinkList from '@/components/DrinkGroup'
  import {combinationEdit, combinationDetail} from '@/api/marketing/combination/index'
  import {checkObj, fillObj} from "@/util/checkLogin";

  export default {
    name: '',
    components: {
      CDialog,
      DrinkList
    },
    mounted() {
      this.initCombination()
    },
    activated: function () {
      this.initCombination()
    },
    data: function () {
      return {
        title: '编辑套餐',
        combination: {
          uuid: '',
          name: '',
          months: '',
          rent: '',
        },
        getDrinkVisible: false,
        freeCups: [],
        billingCups: [],
        loadDrinkObj: 'free'
      }
    },
    methods: {
      handleGoBack() {
        this.$router.back()
      },
      initCombination() {
        let c = this.$route.params
        if (!c) {
          this.handleGoBack()
        }
        this.combination.uuid = c.uuid
        this.combination.name = c.name
        this.combination.months = c.months
        this.combination.rent = c.rent / 100

        combinationDetail({uuid: c.uuid}).then(res => {
          this.freeCups = res.data.freeDrinks || []
          let billings = (res.data.billingsDrinks || [])
          billings.forEach(item => {
            item.price = item.price / 100
            item.middlePrice = item.middlePrice / 100
            item.bigPrice = item.bigPrice / 100
          })
          this.billingCups = billings

        })

      },
      handleAddDrink(drinks, loadMaster = 'free') {
        this.getDrinkVisible = true
        let vue = this
        vue.loadDrinkObj = loadMaster
        this.$nextTick(function () {
          vue.$refs.drinkList.loadDrink(drinks)
        })
      },

      handleDeleteFree(data, index) {
        this.freeCups.splice(index, 1)
      },

      handleDeleteBilling(data, index) {
        this.billingCups.splice(index, 1)
      },

      materielExtraCostChange(item) {
        // 防止删除为空
        if (!item) {
          item = '0.00'
        }
        // 一些错误金额输入的判断
        if (item.toString().indexOf('.') > 0 && Number(item.toString().split('.')[1].length) < 1) {
          item = item.toString().split('.')[0]
        }
        // 一些错误金额输入的判断
        if (!item || item === '-' || item === '-0') {
          item = '0.00'
        }
        return parseFloat(item).toFixed(2)

      },
      transPrice(data) {
        data.price = data.price / 100
        data.middlePrice = data.middlePrice / 100
        data.bigPrice = data.bigPrice / 100
        return data
      },

      handleGetDrink() {
        let drinks = (this.$refs.drinkList.getCheckDrink() || [])
        let setDrinks = []
        switch (this.loadDrinkObj) {
          case "free":
            setDrinks = this.freeCups
            break;
          case "billing":
            setDrinks = this.billingCups
            break;
          default:
            return
        }

        drinks.forEach(item => {
          let i = 0;
          for (; i < setDrinks.length; i++) {
            if (setDrinks[i].drinkUuid === item.drinkUuid) {
              break
            }
          }
          if (i >= setDrinks.length) {
            setDrinks.push(this.transPrice({
              drinkUuid: item.drinkUuid, name: item.name, engName: item.engName,
              price: item.price, middlePrice: item.middlePrice, bigPrice: item.bigPrice,
              cups: 0,
            }))
          }
        })
        this.getDrinkVisible = false
      },

      handleCopyDrink(src, dst) {
        src.forEach(item => {
          let i = 0;
          for (; i < dst.length; i++) {
            if (dst[i].drinkUuid === item.drinkUuid) {
              break
            }
          }
          if (i >= dst.length) {
            dst.push({
              drinkUuid: item.drinkUuid, name: item.name, engName: item.engName,
              price: item.price, middlePrice: item.middlePrice, bigPrice: item.bigPrice,
              cups: item.cups,
            })
          }
        })
      },

      handleCombinationCreate() {
        let check = checkObj(this.combination, [
          {value: "months", message: "请填写套餐时长"},
          {value: "name", message: "请填写套餐名称"},
          {value: "rent", message: "请输入月租金"},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        this.combination.months = parseInt(this.combination.months)
        let c = this.copy(this.combination)
        c.rent = parseInt(c.rent) * 100

        c.freeDrinks = []
        let confirm = false
        this.freeCups.forEach(item => {
          if (item.cups === 0) {
            confirm = true
            return
          }
          c.freeDrinks.push({
            drinkUuid: item.drinkUuid,
            name: item.name,
            engName: item.engName,
            cups: parseInt(item.cups)
          })
        })
        if (confirm) {
          this.$message.warning('免费杯量不允许设置未空')
          return
        }


        c.billingsDrinks = []
        this.billingCups.forEach(item => {
          let price = parseFloat(item.price)
          let middlePrice = parseFloat(item.middlePrice)
          let bigPrice = parseFloat(item.bigPrice)
          if (price === 0 && middlePrice === 0 && bigPrice === 0) {
            confirm = true
            return
          }
          c.billingsDrinks.push({
            name: item.name,
            engName: item.engName,
            drinkUuid: item.drinkUuid, price: Math.floor(price * 100),
            middlePrice: Math.floor(middlePrice * 100),
            bigPrice: Math.floor(bigPrice * 100),
          })
        })

        if (confirm) {
          this.$confirm('未设置价格的饮品会被自动清除, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this._handleCombinationCreate(c)
          }).catch(() => {

          })
        } else {
          this._handleCombinationCreate(c)
        }
      },
      copy(mainObj) {
        let objCopy = {}; // objCopy 将存储 mainObj 的副本
        let key;

        for (key in mainObj) {
          objCopy[key] = mainObj[key]; // 将每个属性复制到objCopy对象
        }
        return objCopy;
      },
      _handleCombinationCreate(c) {
        combinationEdit(c).then(res => {
          this.$message.success('套餐编辑成功')
          this.handleGoBack()
        })
      }


    },
  }
</script>

<style lang="scss">
  .dealer-combination {
    &-title {
      height: 32px;
      line-height: 32px;

      .back {
        margin-right: $margin;
        display: inline-block;
      }

      .back:hover {
        cursor: pointer;
        color: $_theme_color;
      }

      &-name {
        font-weight: 500;
        font-size: 16px;
        margin-right: $margin;
      }
    }

    .alarm {
      color: $btn_color;
    }

    &-param {
      .el-input {
        max-width: 200px;
      }
    }

    &-operate {
      padding: $padding;
    }

    &-cup {
      margin-bottom: $margin;

      &-title {
        .label {
          font-weight: 500;
        }

        .operate {
          font-size: 12px;
          color: $_theme_color;
          margin-left: $padding;
          cursor: pointer;
        }
      }
    }

    &-content {
      margin: $margin;
    }

    .delete {
      color: $btn_sec_color;
      cursor: pointer;
    }

    &-edit-info {
      margin-left: $margin;
      border-radius: $border-radius;

      &-content {
        font-size: 12px;
        @include set_bg_color(rgba(124, 198, 255, 0.56), $background-color-dark-1);
        padding: $padding;
        border-radius: $border-radius;
        list-style-type: none;
        counter-reset: sectioncounter;

        li:before {
          content: counter(sectioncounter) "、";
          counter-increment: sectioncounter;
        }
      }
    }

    &-submit {
      margin: $margin 0;
      text-align: center;
    }


  }
</style>
